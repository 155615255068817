import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import Watch from '../components/Watch';
import Footer from '../components/Footer';
import Join from '../components/Join';
import Listen from '../components/Listen';
// import See from '../components/See';
// import Read from '../components/Read';

const indexQuery = graphql`
  {
    page: datoCmsHomePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    watch: allDatoCmsWatchEntry {
      nodes {
        id
        videoId
        videoProvider
        videoTitle
        featuredImage {
          fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
    listen: datoCmsListen {
      spotifyUri
    }
  }
`;

export default function IndexPage() {
  const data = useStaticQuery(indexQuery);
  const { seoMetaTags } = data.page;
  const { nodes: watchNodes } = data.watch;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Watch watchNodes={watchNodes} />
      <Join />
      <Listen data={data.listen} />
      <Footer />
      {/*
      <See />
      <Read /> */}
    </Fragment>
  );
}
