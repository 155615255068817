import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import PlaySrc from '../images/play.svg';
import VideoContext from '../store/videoContext';
import { Container, InnerSmall, SectionTitle } from './Elements';
import { colors, font } from '../consts/style';

const Wrapper = styled.div`
  margin-bottom: 20rem;
  ${InnerSmall} {
    display: flex;
    flex-direction: column;

    .video-nodes {
      display: flex;
      flex-wrap: wrap;
      .video-node {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 2%;
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .image {
          margin-bottom: 1.6rem;
          position: relative;
          .play-btn {
            width: 4rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .title {
          p {
            ${font.body}
            color: ${colors.grey};
          }
        }
      }
    }
  }
`;

const Artwork = styled(Img)``;

export default function Watch({ watchNodes }) {
  const { videoShow } = useContext(VideoContext);

  function renderVideoNodes() {
    return watchNodes.map(n => {
      return (
        <button
          key={n.id}
          className="video-node"
          onClick={() => videoShow(n.videoId, n.videoProvider)}
        >
          <div className="image">
            <Artwork fluid={n.featuredImage.fluid} />
            <img className="play-btn" src={PlaySrc} alt="Play video" />
          </div>
          <div className="title">
            <p>{n.videoTitle}</p>
          </div>
        </button>
      );
    });
  }
  return (
    <Wrapper id="watch">
      <Container>
        <InnerSmall>
          <SectionTitle>Watch</SectionTitle>
          <div className="video-nodes">{renderVideoNodes()}</div>
        </InnerSmall>
      </Container>
    </Wrapper>
  );
}

Watch.propTypes = {
  watchNodes: PropTypes.array.isRequired,
};
