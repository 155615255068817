import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SpotifyPlayer from 'react-spotify-player';

import { Container, InnerSmall, SectionTitle } from './Elements';

const Wrapper = styled.div`
  margin-bottom: 20rem;
  ${InnerSmall} {
    display: flex;
    flex-direction: column;
  }
`;

export default function Listen({ data }) {
  return (
    <Wrapper id="listen">
      <Container>
        <InnerSmall>
          <SectionTitle>Listen</SectionTitle>
          <SpotifyPlayer
            uri={data.spotifyUri}
            size={{ width: '100%', height: '300px' }}
            view={'coverart'}
            theme={'black'}
          />
        </InnerSmall>
      </Container>
    </Wrapper>
  );
}

Listen.propTypes = {
  data: PropTypes.object.isRequired,
};
