import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import fetch from 'isomorphic-unfetch';

import { colors } from '../consts/style';
import { subscribeUrl } from '../consts/endpoints';

const Wrapper = styled.div`
  width: 66rem;
  max-width: 100%;
  p {
    text-align: center;
    margin-top: 1.2rem;
    color: ${colors.grey};
    font-weight: bold;
    text-transform: uppercase;
  }
  form {
    width: 100%;
    display: flex;
    border-radius: 0.4rem;
    overflow: hidden;
    font-weight: bold;
    border: 0.2rem solid ${colors.grey};
    font-size: 1.6rem;
    transition: all 0.4s ease;
    opacity: ${props => (props.loading === 'true' ? '.5' : '1')};
    input {
      width: 100%;
      padding: 1.2rem 1.6rem;
      border: none;
      background: transparent;
      color: ${colors.grey};
      font-weight: bold;
      font-size: 1.6rem;
      outline: none;
      &::placeholder {
        color: ${colors.grey};
        opacity: 0.5;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.6rem;
      }
    }
    button {
      border: none;
      padding: 0 1.2rem;
      text-transform: uppercase;
      font-weight: bold;
      background: ${colors.grey};
      color: ${colors.purple};
      font-size: 1.6rem;
    }
  }
`;

const subQuery = graphql`
  {
    datoCmsMisc {
      mailchimpListId
    }
  }
`;

export default function Subscribe() {
  const {
    datoCmsMisc: { mailchimpListId },
  } = useStaticQuery(subQuery);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  function handleSubmit(e) {
    if (e) e.preventDefault();
    if (loading) return null;

    setLoading(true);

    const body = JSON.stringify({
      email,
      mailchimpListId,
    });

    fetch(subscribeUrl, {
      method: 'POST',
      body,
    })
      .then(res => res.json())
      .then(res => handleResponse(res))
      .catch(() => handleError('Oops! Something went wrong.'));
  }

  function handleResponse(res) {
    if (res.response.statusCode <= 204 || res.response.status <= 204) {
      setLoading(false);
      setSuccess(true);
      setEmail('');
      setError(null);
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
    } else handleError(res.response.title);
  }

  function handleError(msg) {
    setLoading(false);
    setSuccess(false);
    setError(msg);
  }
  return (
    <Fragment>
      <Wrapper loading={loading ? 'true' : 'false'}>
        <form onSubmit={handleSubmit}>
          <input
            required
            type="email"
            value={email}
            placeholder="Email"
            readOnly={loading}
            onChange={e => setEmail(e.target.value)}
          />
          <button type="submit">
            <span>Subscribe</span>
          </button>
        </form>
        {success && <p>Thanks for subscribing!</p>}
        {error && <p>{error}</p>}
      </Wrapper>
    </Fragment>
  );
}
