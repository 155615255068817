import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import map from 'lodash/map';

import Subscribe from './Subscribe';
import { gradient, font, colors } from '../consts/style';

const footerQuery = graphql`
  {
    datoCmsMisc {
      email
      socialMedia {
        id
        link
        icon {
          fluid(maxWidth: 1800, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 2rem;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  h3 {
    color: ${colors.grey};
    ${font.h2};
    margin: 0;
    padding: 0 1.2rem;
  }
  .red {
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    flex-shrink: 0;
    ${gradient.red};
  }
  .blue {
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    flex-shrink: 0;
    ${gradient.blue};
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;
  a {
    margin-right: 1.2rem;
    &:last-child {
      margin: 0;
    }
  }
`;

const SocialImage = styled(Img)`
  width: 2.8rem;
`;

export default function Footer() {
  const { datoCmsMisc } = useStaticQuery(footerQuery);

  function renderSocials() {
    return map(datoCmsMisc.socialMedia, s => (
      <a target="blank" key={s.id} href={s.link}>
        <SocialImage fluid={s.icon.fluid} />
      </a>
    ));
  }
  return (
    <Fragment>
      <Wrapper>
        <Title>
          <h3>{datoCmsMisc.email}</h3>
        </Title>
        <Subscribe />
        {datoCmsMisc.socialMedia.length && <Socials>{renderSocials()}</Socials>}
      </Wrapper>
    </Fragment>
  );
}
