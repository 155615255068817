import React, { Fragment } from 'react';
import styled from 'styled-components';
import Subscribe from './Subscribe';
import { gradient, font, colors } from '../consts/style';

const Wrapper = styled.div`
  margin-bottom: 20rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  h3 {
    color: ${colors.grey};
    ${font.h1};
    margin: 0;
    padding: 0 1.2rem;
  }
  .red {
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    flex-shrink: 0;
    ${gradient.red};
  }
  .blue {
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    flex-shrink: 0;
    ${gradient.blue};
  }
`;

export default function Join() {
  return (
    <Fragment>
      <Wrapper id="join">
        <Title>
          <div className="red" />
          <h3>JOIN OUR INNER CIRCLE</h3>
          <div className="blue" />
        </Title>
        <Subscribe />
      </Wrapper>
    </Fragment>
  );
}
